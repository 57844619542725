import {
	CREATE_QUIZ,
	CREATE_QUIZ_LOADING,
	DELETE_QUIZ,
	DELETE_QUIZ_LOADING,
	UPDATE_QUIZ,
	UPDATE_QUIZ_LOADING,
} from "./quiz.types";

const INITIAL_STATE = {
	create_quiz: {
		payload: null,
		error: null,
		loading: false,
	},
	update_quiz: {
		payload: null,
		error: null,
		loading: false,
	},
	delete_quiz: {
		payload: null,
		error: null,
		loading: false,
	},
};

const quizReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CREATE_QUIZ_LOADING:
			return {
				...state,
				create_quiz: {
					loading: action.isLoading,
				},
			};
		case CREATE_QUIZ:
			return {
				...state,
				create_quiz: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case UPDATE_QUIZ_LOADING:
			return {
				...state,
				update_quiz: {
					loading: action.isLoading,
				},
			};
		case UPDATE_QUIZ:
			return {
				...state,
				update_quiz: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		case DELETE_QUIZ_LOADING:
			return {
				...state,
				delete_quiz: {
					loading: action.isLoading,
				},
			};
		case DELETE_QUIZ:
			return {
				...state,
				delete_quiz: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default quizReducer;
